<script setup lang="ts">
import { ContentImageProps } from '../types'

const props = withDefaults(defineProps<ContentImageProps>(), {
  title: '',
  isAdaptive: true,
  isHiddenMobile: false,
  isLazy: true
})

const imageSrc = computed(() => getImagePath(props.src))
const size = computed(() => ({
  width: (props.src && props.src.width) || '',
  height: (props.src && props.src.height) || ''
}))
const imageSrcSet = computed(() => getImageSrcSet(props.srcset, props.src))
</script>
<template>
  <AsfImage
    class="asf-content-image"
    :src="imageSrc"
    :srcset="imageSrcSet"
    :alt="alt"
    :title="title"
    :hidden-mobile="isHiddenMobile"
    :adaptive="isAdaptive"
    :lazy="isLazy"
    :observer-options="observerOptions"
    :width="size.width"
    :height="size.height"
  />
</template>
<style src="~/styles/ContentImage.css"></style>
