<script setup lang="ts">
import '../styles/ContentModal.css'
import { AmplienceContent, ContentHeadingProps, ContentModalProps } from '../types'

const props = withDefaults(defineProps<ContentModalProps>(), { visible: false })
const emit = defineEmits(['close:modal'])

const { searchContent, content } = useContent('content-modal')
const contentHeight = ref('0')
const title = ref<ContentHeadingProps>()
const children = ref<AmplienceContent[]>()
const visibleAndLoaded = ref<boolean>(false)

const getContent = async () => {
  await searchContent({ keys: [props.id] })
}
const close = () => emit('close:modal', true)

watch(content, () => {
  title.value = content.value?.[props?.id]?.at(0)?.title
  children.value = content.value[props.id]?.at(0)?.children

  setTimeout(() => {
    visibleAndLoaded.value = props.visible
  }, 150)
})

onMounted(() => {
  getContent()
  nextTick(() => {
    if (process.client) {
      contentHeight.value = String(window.innerHeight - 190)
    }
  })
})
</script>
<template>
  <AsfModal
    v-if="visibleAndLoaded"
    class="asf-content-modal"
    appearance="md"
    :visible="visibleAndLoaded"
    use-focus-trap
    aria-modal="true"
    role="dialog"
    @close="close"
  >
    <template v-if="title" #content-title="{ classNames }">
      <ContentHeading v-bind="title" :class="classNames" />
    </template>
    <div
      v-if="children"
      class="overflow-x-hidden overflow-y-auto scrollbar-light pr-3"
      :style="{ height: `${contentHeight}px` }"
    >
      <ContentSlot :content="children" class="asf-content" />
    </div>
  </AsfModal>
</template>
